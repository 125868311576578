<template>
  <div id="category-management">

    <table-action-row
      v-if="!isReordering"
      :filterList="listQuery"
      @onFilterChange="value => { listQuery = value; getList() }"
      :handleAction="fullAccess ? () => goTo('/bo/category/detail') : null"
      actionButtonCode="ADD_CATEGORY"
      actionButtonIcon="icon-add"
    >
      <template slot="additional-items">
        <el-button 
          class="button black action-button" 
          @click="startReordering"
        >
          <span>{{ $t("REORDER") }}</span>
        </el-button>
      </template>
    </table-action-row>

    <el-row 
      v-else 
      type="flex" 
      class="table-action-row"
      :justify="isMobile ? 'start' : 'end'"
    >
      <el-button 
        class="button black action-button" 
        @click="saveOrdering"
      >
        <span>{{ $t("SAVE") }}</span>

      </el-button>
      <el-button 
        class="button black action-button" 
        @click="cancelOrdering"
      >
        <span>{{ $t("CANCEL") }}</span>
      </el-button>
    </el-row>

    <el-table-draggable
      :disabled="!isReordering"
      @input="onDragEnd"
      handle=".drag-handle"
    >
      <el-table
        v-loading="isLoading"
        class="newsily-bo-table"
        row-key="id"
        :data="list"
      >
        <el-table-column
          v-if="isReordering"
          width="30"
        >
          <span 
            class="drag-handle"
          >::</span>
        </el-table-column>
        <el-table-column
          prop="category"
          :label="$t('CATEGORY')"
          :width="250"
        >
          <template slot-scope="{row}">
            {{ row | translate }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('SUB_CATEGORY')">
          <template slot-scope="{ row }">
            <div v-if="row.childCategory && row.childCategory.length > 0" class="sub-category-column">
              <template v-for="(secondCategory, secondCategoryIndex) in row.childCategory">
                <el-popover
                  v-if="secondCategory.childCategory && secondCategory.childCategory.length > 0"
                  :key="secondCategory.id"
                  placement="right"
                  trigger="hover"
                  popper-class="category-popover"
                >
                  <li
                    v-for="thirdCategory in secondCategory.childCategory"
                    :key="thirdCategory.id"
                  >
                    {{ thirdCategory | translate }}
                  </li>
                  <el-tag slot="reference" class="sub-category-tag">
                    {{ secondCategory | translate }}
                  </el-tag>
                </el-popover>
                <el-tag v-else :key="`${secondCategory.id}-${secondCategoryIndex}`" class="sub-category-tag">
                  {{ secondCategory | translate }}
                </el-tag>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          :label="$t('STATUS')"
          width="100"
        >
          <template slot-scope="{row}">
            <div>
              <span :class="getStatusClass(row.status)">{{ $t(row.status) }}</span>
              <el-dropdown
                class="action-button"
                :hide-on-click="false"
                trigger="click"
              >
                <i class="custom-icon icon-edit mini center-in-div pointer"/>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item 
                    @click.native="goTo(`/bo/category/detail/${row.id}`)"
                  >
                    {{ fullAccess ? $t("EDIT") : $t("VIEW") }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
  </div>
</template>

<script>
import { genericRecordsListMixin } from '@/utils/_mixins/generic-records-list.js';
import { EventBus } from "@/utils/event-bus.js"
import TableActionRow from '@/components/table/table-action-row.vue'
import cloneDeep from "lodash/cloneDeep"
import ElTableDraggable from "el-table-draggable";
import { bulkUpdateGenericRecordsDetail } from "@/api/utility"

const filterQuery = {
  search: "",
  limit: null, // load ALL
  // for API
  genericRecord: {
    type: 'category'
  }
};

export default {
  name: "CategoryManagement",
  mixins: [genericRecordsListMixin],
  components: {
    TableActionRow, ElTableDraggable
  },
  data() {
    return {
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
      isReordering: false,
      reorderedList: [],
      beforeReorderedList: [],
    }
  },
  methods: {
    // REDIRECTION
    goToEdit(id) {
      this.goTo(`/bo/category/detail/${id}`)
    },
    handleAfterArchive() {
      EventBus.$emit("refresh-category")
    },
    onDragEnd(result) {
      this.reorderedList = result
    },
    startReordering() {
      this.isReordering = true
      this.beforeReorderedList = cloneDeep(this.list)
      this.reorderedList = []
    },
    saveOrdering() {
      this.isReordering = false

      if (this.reorderedList.length <= 0) return;
      const mappedList = cloneDeep(this.reorderedList)
        .map((el, index) => {
          el.sequences = index
          return el
        })
      
      this.isLoading = true
      bulkUpdateGenericRecordsDetail({ grList: mappedList })
        .then((res) => {
          if (res.status !== "ok") return

          this.$notify({
            title: this.$t("SUCCESS"),
            message: this.$t("REORDER_SUCCESSFULLY"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })

          EventBus.$emit("refresh-category")
        })
        .finally(() => {
          this.isLoading = false
          this.getList()
        })


    },
    cancelOrdering() {
      this.isReordering = false

      this.list = this.beforeReorderedList
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/style/mixin/table.scss";

#category-management {
  position: relative;
  @include newsily-bo-table;
  .action-button {
    float: right;
  }

  .drag-handle {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

  .sub-category-column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.875rem;

    .sub-category-tag {
      display: flex;
      align-items: center;
      border: 0.063rem solid #F0F0F0;
      border-radius: 0.313rem;
      padding: 0.5rem;
      background-color: #F3F5FA;
      color: #9893B7;
      font: normal normal normal 0.75rem SourceSansPro;
    }
  }
}
</style>